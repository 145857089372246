<template>
  <div class="front-cont f-page-bg pt-3 pb-5">
    <div class="row px-3 px-md-7 mt-3 pt-5 pt-md-0">
      <div class="col-md-12 px-md-1">
        <el-card class="p-4">
          <!-- <i
            class="el-icon-close c-pointer"
            @click="$r.goBack"
            style="
              position: absolute;
              right: 24px;
              top: 24px;
              font-size: 20px;
              font-weight: bold;
              color: #707070;
            "
          ></i> -->

          <div class="row">
            <div class="col-md-3">
              <el-image
                v-if="$h.formatName(Service, 'Logo')"
                class="service-img mb-3"
                fit="contain"
                alt="..."
                :src="
                  $axios.formulateUrl(`/image/view/tmp/${$h.formatName(Service, 'Logo')}`)
                "
              />

              <el-image
                v-if="Service?.ServicePicture"
                class="service-img mb-3"
                fit="contain"
                alt="..."
                :src="$axios.formulateUrl(`/image/view/tmp/${Service.ServicePicture}`)"
              />
            </div>

            <div class="col-md-1 d-flex align-items-center justify-content-center">
              <div class="f-bg-orange ser-desc-sep" />
            </div>

            <div class="col-md-8 py-3">
              <!--<div style="height: 100%" class="f-bg-orange px-0 px-md-1 mx-0 mx-md-3" />-->

              <h1 class="section-title mb-4">
                {{ $h.formatName(Service, "Name") }}
                <span v-if="$h.formatName(Service, 'NameExtra', true)">
                  - {{ $h.formatName(Service, "NameExtra") }}</span
                >
              </h1>

              <section
                class="tiny-view mb-4"
                v-html="$h.formatName(Service, 'Description')"
              ></section>

              <div class="mb-3">
                <el-button
                  type="info"
                  @click="applyNow"
                  class="f-btn-common f-btn-orange"
                  style="width: 100%"
                >
                  <b>{{ $t.translate("BTN_APPLY_NOW") }}</b
                  ><i class="ml-1 el-icon-arrow-right"></i>
                </el-button>
              </div>
              <div class="mb-0 mb-md-1">
                <el-button
                  type="info"
                  @click="viewCases"
                  class="f-btn-common f-btn-orange"
                  style="width: 100%"
                >
                  <b>{{ $t.translate("BTM_VIEW_COMPLETED_CASES") }}</b
                  ><i class="ml-1 el-icon-arrow-right"></i>
                </el-button>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, onUpdated, ref, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { router } from "@/utils/router";
import { store } from "@/store";

export default {
  setup() {
    const route = useRoute();
    let formData = reactive({});
    let Service = ref({});

    onMounted(async () => {
      loadItems();
    });

    const loadItems = async () => {
      let res = await get("/public/service", {
        page: 1,
        size: 1,
        AssessmentServiceKey: route.params.key,
      });
      Service.value = res?.data?.[0];
    };

    const applyNow = async () => {
      router.changeRoute("/application/add", {
        service: Service.value.AssessmentServiceKey,
      });
    };

    const viewCases = async () => {
      router.changeRoute("/disclosure", {
        service: Service.value.AssessmentServiceKey,
        searched: 1,
      });
    };

    return { formData, Service, loadItems, applyNow, viewCases };
  },
};
</script>
<style>
.service-img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}
.ser-desc-sep {
  height: 100%;
  width: 13px;
  margin-right: 0.5rem;
}

@media (max-width: 1200px) {
  .ser-desc-sep {
    width: 12px;
    margin-right: 0px;
  }
}
@media (max-width: 768px) {
  .ser-desc-sep {
    display: none;
  }
}
</style>
